import React from 'react'
import { useDataProvider } from '../../../components/DataProvider'

export default function TopLogo() {
    const { ImageTop } = useDataProvider();

    return (
        <div>
            {ImageTop.map(url => <img src={url} alt="" />)}
        </div>
    )
}
