import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import classNames from 'classnames';

interface InputSignProps {
    title: string | any
    handleChange: (e: string) => void
    isWrongCredentials?: boolean,
    type?: string,
    value: string,
}
const InputSign: FC<InputSignProps> = ({ title, value, handleChange, isWrongCredentials, type }) => {
    const { t } = useTranslation();

    return (
        <div className="tw3-w-[100%] tw3-pr-[10px]">
            <p className={'tw3-mb-[8px]'}>{title}</p>
            <input
                value={value}
                type={type ? type : 'text'}
                placeholder={title}
                className={
                    classNames(
                        'tw3-text-slate-800',
                        'tw3-border-[1px] tw3-divide-[#243c5a] tw3-py-[15px] tw3-pl-[15px] tw3-w-[100%] tw3-rounded-[12px] tw3-outline-none',
                        { 'tw3-border-[#EF4444]': isWrongCredentials }
                    )
                }
                onChange={(e) => handleChange(e.target.value)}
            />
            {isWrongCredentials && <p className={'tw3-text-[#EF4444] tw3-text-[12px]'}>{t('Neteisingas slaptažodis arba prisijungimo vardas')}</p>}
        </div>
    );
}

export default InputSign;
