import { axiosInstance } from "./config";

export type CreateUserDto = {
  email: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  password?: string;
  username?: string;
};

export const createUser = (data: CreateUserDto) => {
  return axiosInstance
    .post("sign-up", {
      data,
      origin: window.location.href,
    })
};

type LoginUserDto = {
  password: string;
  username: string;
};

export const loginUser = (data: LoginUserDto) => {
  return axiosInstance
    .post("sign-in", {
      data,
      origin: window.location.href,
    })
};


type RemindUserDto = {
  username: string;
};

export const remindUser = (data: RemindUserDto) => {
  axiosInstance
    .post("remind", {
      data,
      origin: window.location.href,
    })
    .then(() => {});
};


type ChangepasswordDto = {
  password: string;
  token: string;
};

export const changePassword = (data: ChangepasswordDto) => {
  return axiosInstance
    .post("change-password", {
      data,
      origin: window.location.href,
    })
};
